@import "../../App.scss";

.Card {
  border-radius: 0.5rem;
  background: #fafafa;
  color: #222;

  &:not(.Card__flat) {
    @include card-shadow;
  }

  &.Card__flat {
    background: transparent;
  }

  .Card__body {
    height: calc(100% - 4rem);
    padding: 1rem;

    .Card__icon {
      text-align: center;

      img, svg {
        width: 80px;
        height: 80px;
        margin: 1rem;
      }
    }
  }

  .Card__title {
    font-family: Archivo, sans-serif;
    font-size: xx-large;
    font-weight: bolder;
    text-align: center;
  }

  .Card__subtitle {
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
  }
}
