@import "../../App.scss";

.Section {
  .Section__Main {
    @include card-shadow;
    position: relative;
    margin: 0;

    .Section__title {
      font-family:Archivo,sans-serif;
      position: relative;
      background-color: #0a7fa2;
      color: #fafafa;
      padding: 1.5rem 2rem 1rem;
      margin: 0;
      display: inline-block;
      min-width: 75%;
      z-index: 100;
      font-size: 1.5rem;
      border-radius: 0.5rem;
      border-bottom-left-radius: 0;
    }

    .Section__body {
      position: relative;
      top: -3rem;
      padding: 5rem 1rem 2rem;
      width: calc(100% - 2rem);
      background-color: #fafafa;
      color: #222;
      margin-bottom: -2rem;
      border-radius: 0.5rem;
    }
  }

  @media screen and (min-width: 600px) {
    .Section__Main {
      margin: 0;
      .Section__title {
        font-size: 3rem;
      }

      .Section__body {
        width: calc(100% - 2rem);
      }
    }
  }
}
