@mixin card-shadow {
  filter: drop-shadow(0px 4px 2px #0003);
}

body, html {
  width: 100%;
  height: 100%;
}

.body {
  font-family: 'Monda', sans-serif;
}

.title {
  font-family: 'Julius Sans One', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.bg-white {
  background: #fafafa;
}

.bg-primary {
  background: linear-gradient(90deg, #fafafa -25%, #cfcfcf 110%);
}

#bg {
  background: rgb(255, 255, 255);
  //background: linear-gradient(35deg, rgb(250, 250, 250) -10%, rgb(179, 0, 255) 25%, rgb(78, 4, 196) 78%, rgb(74, 9, 121) 110%);
  //background: linear-gradient(35deg, rgb(250, 250, 250) -10%, rgb(179, 0, 255) 25%, rgb(4, 119, 196) 78%, rgb(74, 9, 121) 110%);
  background: linear-gradient(35deg, #fafafa -25%, #08a6df 25%, #08a6df 78%, #61dafb 110%);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.view {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.hero__container {
  height: 12rem;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #08a6df;

  @media screen and (min-width: 600px) {
    height: 20rem;
  }
}

.hero {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
}

.container {
  max-width: 1200px;
  padding: 0 0.75rem;
  width: calc(100% - 1.5rem);
  margin: 0 auto;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-content-center {
  justify-content: center;
}

.align-items-end {
  align-items: flex-end;
}

.rel-75 {
  position: relative;
  top: -75px;
}

.rel-8 {
  position: relative;
  top: -8rem;
  margin-bottom: -10rem;
}

.quote {
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.6);

  &::before {
    content: "„";
  }

  &::after {
    content: "“";
  }
}

.card, .glass__card {
  @include card-shadow;
  border-radius: 0.5rem;
}

.card {
  background: #fafafa;
  color: #222;

  .card__body {
    padding: 2rem;

    .card__icon {
      text-align: center;
    }
  }
}

.glass__card {
  background: linear-gradient(
                  152deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.1) 100%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  color: white;

  .glass__card__body {
    padding: 2rem;

    .glass__card__icon {
      text-align: center;
    }
  }
}

#navigation {
  position: sticky;
  top: 0;
  left: 0;
  height: 4rem;
  z-index: 100;
  background-color: #fafafa;
  box-shadow: 0 4px 8px 0 rgba(31, 38, 135, 0.37);
  transition: all ease-in-out 300ms;
  opacity: 0;

  .navigation__link {
    margin: 0.5rem 1.5rem;
  }
}

.avatar__container {
  display: flex;
  justify-content: center;
}

.avatar {
  border-radius: 50%;
  border: 8px solid rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  overflow: hidden;

  margin-bottom: 2rem;
}

.title {
  font-size: xx-large;
  font-weight: bolder;
  text-align: center;
}

.h-100 {
  height: 100%;
}

.h-75 {
  height: 75%;
}

.w-100 {
  width: 100%;
}

.subtitle {
  text-align: center;
}

.mr-2 {
  margin-right: 1rem;
}

.gap-2 {
  gap: 0.5rem; 
}

.my-1 {
  margin-top: -5rem;
  margin-bottom: .5rem;
}

.m-2 {
  margin: 1rem;
}

.m-md-2 {
  @media screen and (min-width: 600px) {
    margin: 1rem;
  }
}

.mt-2, .my-2 {
  margin-top: 1rem;
}

.mb-2, .my-2 {
  margin-bottom: 1rem;
}

.mt-3, .my-3 {
  margin-top: 3rem;
}

.mb-3, .my-3 {
  margin-bottom: 3rem;
}

.my-6 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.mb-12 {
  margin-bottom: 12rem;
}

.mt-1 {
  margin-top: .5rem;
}

.pt-2, .py-2 {
  padding-top: 2rem;
}

.pb-2, .py-2 {
  padding-bottom: 2rem;
}

.pb-12 {
  padding-bottom: 12rem;
}

.small {
  font-size: small;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.availability__scale {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 100rem;
  width: 100%;
  height: 4px;
  position: relative;

  .availability {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(0, 255, 0, 0.7);
    border-radius: 100rem;
    transition: width ease-in-out 1200ms;

    &.not-available {
      background-color: rgba(255, 0, 0, 0.7);
    }
  }
}

.available {
  color: rgba(0, 255, 0, 0.7);
  font-weight: bold;
}

.button, .glass__button {
  border-radius: 100rem;
  height: 2rem;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
  cursor: pointer;
}

.button {
  border: 1px solid #0a7fa2;
  background: rgba(10, 127, 162, 1);
  color: #fafafa;

  &:hover {
    background: rgba(10, 127, 162, 0.8)
  }

  &.outline {
    border: 1px solid #fafafa;
    background-color: transparent;
    color: #fafafa;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

}

.glass__button {
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.1);

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.4);
    position: relative;
    transform: translate3d(0, 1px, 0);
  }
}

#more {
  transition: all 600ms ease-in-out;
}

.read__more {
  display: none;

  @media (min-width: 600px) {
    display: flex;
  }

  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 3rem;
  color: white;
  border-radius: 100rem;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  min-width: 80px;
  animation: more-drop 5s infinite ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .more {
    margin-bottom: 0.5rem;
    font-size: 2rem;
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.4);
  }

  .arrow {
    margin: 0 1rem;
  }
}

.overview, .cols-3, .cols-2, cols-lg-3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;

  > div {
    width: 100%;
  }
}

@media screen and (min-width: 600px) {
  .overview, .cols-3 {
    > div {
      width: calc(33.333333% - 2rem);
      margin: 1rem;
    }
  }
  .cols-2 {
    > div {
      width: calc(50% - 0.5rem);
    }
  }
}
@media screen and (min-width: 1200px) {
  .overview, .cols-lg-3 {
    > div {
      width: calc(33.333333% - 2rem);
      margin: 1rem;
    }
  }
  .cols-lg-2 {
    > div {
      width: calc(50% - 2rem);
      margin: 1rem;
    }
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes more-drop {
  0%, 20% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(8px);
  }
}

.trainer__request {
  color: #fafafa;
  max-width: 600px;
}

.impressum {
  word-break: break-word;
}

