.Footer {

  .Footer__container {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 500px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 10%, rgba(30, 30, 30, 0.9) 75%);
    display: flex;
    color: #fafafa;
    justify-content: space-between;

    .Footer__name, .Footer__impressum, .Footer__date {
      padding: 0 2rem;
    }

    .Footer__name {
      order: 2;
      width: calc(50% - 4rem);
    }
    .Footer__impressum {
      order: 1;
      text-align: center;
      width: 100%;
    }
    .Footer__date {
      order: 3;
      text-align: right;
      width: calc(50% - 4rem);
    }

    @media screen and (min-width: 600px) {
      .Footer__name {
        order: 1;
        width: calc(33.3333% - 6rem);
      }
      .Footer__impressum {
        order: 2;
        width: calc(33.3333% - 6rem);
      }
      .Footer__date {
        order: 3;
        width: calc(33.3333% - 6rem);
      }
    }
  }
}
