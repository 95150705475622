
.HeroImage {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .HeroImage__img {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
    transition: transform linear 10ms;
  }
}
