
.social__links {
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  .social__link__button {
    margin: .5rem;
    padding: .5rem;
    cursor: pointer;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fafafa;

    a {
      color: unset;
      text-decoration: none;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}
