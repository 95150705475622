.Skill {
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    margin-top: 0.35rem;
    font-size: 0.65rem;
    color: rgba(0, 0, 0, 0.6);
  }
}
